import Box from '@mui/material/Box'

import type { CountryLanguageSelectorProps } from './CountryLanguage.types'
import { CountryLanguageRegion } from './CountryLanguageRegion'

const CountryLanguageDesktop = ({ regions, dataCy }: CountryLanguageSelectorProps) => {
  const countries = Object.values(regions)
  const continents = Object.keys(regions)

  return (
    <Box data-cy={`${dataCy}-desktop`}>
      {countries.map((innerCountries, index) => (
        <CountryLanguageRegion
          data-cy={`${continents[index]}-link`}
          countries={innerCountries}
          continent={continents[index]}
          key={continents[index]}
        />
      ))}
    </Box>
  )
}

export default CountryLanguageDesktop
