import { useTranslation } from 'next-i18next'

import type { Styles } from '@knauf-group/ct-designs/utils/types'
import { setSx } from '@knauf-group/ct-designs/utils/utils'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { CountryLanguageRegionProps } from './CountryLanguage.types'
import CountryLanguageLink from './CountryLanguageLink'

const CountryLanguageAccordionEntry: React.FC<CountryLanguageRegionProps> = ({
  dataCy = 'country-language',
  countries,
  continent,
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'region' })
  const theme = useTheme()
  if (!countries) {
    return null
  }
  const transitionStyle = 'rotate 300ms ease-in-out 300ms'
  const iconTransition = 'transform 300ms ease-in-out 0ms'
  const accStyles: Styles = {
    accordionEntry: {
      boxShadow: 'none',
      background: 'transparent',
      transition: transitionStyle,
      ':before': {
        content: 'none',
      },
    },
    accordionEntrySummary: {
      height: '48px',
      transition: transitionStyle,
      '& .MuiAccordionSummary-content': {
        transition: transitionStyle,
        margin: `${theme.spacing(3)} 0`,
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        color: theme.palette.secondary.main,
        transition: iconTransition,
      },
    },
    accordionEntryDetails: {
      display: 'flex',
      flexDirection: 'column',
      p: `0 ${theme.spacing(2)} ${theme.spacing(3)}`,
      '& :last-child': {
        marginBottom: 0,
      },
      ml: theme.spacing(2),
    },
    expandIcon: { transition: iconTransition },
  }

  return (
    <>
      <Accordion
        disableGutters
        sx={accStyles.accordionEntry}
        data-cy={`${dataCy}-accordion-entry`}
      >
        <AccordionSummary
          sx={accStyles.accordionEntrySummary}
          expandIcon={<ExpandMoreIcon sx={[...setSx(accStyles.expandIcon)]} />}
        >
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.primary,
              fontWeight: 600,
              mt: 0,
              mb: 0,
            }}
          >
            {t(continent)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={accStyles.accordionEntryDetails}>
          {countries?.map((country, index) => {
            return (
              <CountryLanguageLink
                key={index}
                link={{ label: country.label, href: country.href }}
                locale={country.locale}
              />
            )
          })}
        </AccordionDetails>
      </Accordion>
      <Divider />
    </>
  )
}

export default CountryLanguageAccordionEntry
