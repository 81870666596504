import type React from 'react'
import { useTranslation } from 'next-i18next'

import { ScreenXS } from '@knauf-group/ct-designs/utils/hooks/mediaQuery'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { CountryLanguageSelectorProps } from './CountryLanguage.types'
import CountryLanguageAccordion from './CountryLanguageAccordion'
import CountryLanguageDesktop from './CountryLanguageDesktop'

export const CountryLanguageSelector: React.FC<CountryLanguageSelectorProps> = ({
  dataCy = 'language-selector',
  regions,
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'region' })
  const theme = useTheme()
  const isScreenXs = ScreenXS(theme)
  const styles: Styles = {
    mainHeadline: {
      py: { xs: theme.spacing(4), lg: theme.spacing(6) },
      textAlign: 'center',
      '&.MuiTypography-root': {
        marginBottom: 0,
      },
    },
  }

  return (
    <Container data-cy={dataCy}>
      <Typography variant="h1" sx={styles.mainHeadline}>
        {t('chooseYourRegion')}
      </Typography>
      {isScreenXs ? (
        <CountryLanguageAccordion data-cy={dataCy} regions={regions} />
      ) : (
        <CountryLanguageDesktop data-cy={dataCy} regions={regions} />
      )}
    </Container>
  )
}

export default CountryLanguageSelector
