import type React from 'react'
import router from 'next/router'
import { useTranslation } from 'next-i18next'

import { useOnCountryLanguageClick } from '@cms/components/CountryLanguageSelector/useOnCountryLanguageClick'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { CountryLanguageRegionProps } from './CountryLanguage.types'
import CountryLanguageLink from './CountryLanguageLink'

const COUNTRIES_PER_CHUNCK = 12

export const CountryLanguageRegion: React.FC<CountryLanguageRegionProps> = ({
  dataCy = 'contry-area',
  countries,
  continent,
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'region' })
  const theme = useTheme()
  const onCountryLanguageClick = useOnCountryLanguageClick()

  if (!countries) {
    return null
  }

  const sliceCountries = (countriesToSlice) => {
    const perChunk = COUNTRIES_PER_CHUNCK

    return countriesToSlice?.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])
  }

  const styles: Styles = {
    countryWrapper: {
      mb: { xs: theme.spacing(5), md: theme.spacing(6), lg: theme.spacing(7) },
    },
    mainHeadline: {
      py: { xs: theme.spacing(4), lg: theme.spacing(6) },
      textAlign: 'center',
      '&.MuiTypography-root': {
        marginBottom: 0,
      },
    },
    regionHeadline: {
      fontWeight: 600,
      minHeight: '32px',
      marginBottom: theme.spacing(1),
    },
    resetSpacing: {
      '&.MuiGrid-item': {
        py: 0,
        my: 0,
      },
      '&.MuiGrid-container': {
        py: 0,
        my: 0,
      },
    },
    listItemContainer: {
      alignItems: 'baseline',
      paddingLeft: '16px',
      cursor: 'pointer',
      '&.MuiListItem-root:hover': {
        background: theme.palette.action.hover,
      },
      '& .MuiLink-root': {
        color: theme.palette.text.primary,
      },
    },
  }

  const countriesSliced = sliceCountries(countries)

  return (
    <Box sx={styles.countryWrapper} data-cy={dataCy}>
      <Typography variant="h6" component="h2" sx={styles.regionHeadline}>
        {t(continent)}
      </Typography>
      <Grid container sx={styles.resetSpacing}>
        {countriesSliced?.map((countryItem, index) => (
          <Grid item xs={12} sm={3} key={index as React.Key} sx={styles.resetSpacing}>
            <List disablePadding sx={{ border: 0 }}>
              {countryItem?.map((country, indexItem) => (
                <ListItem
                  key={`${indexItem}_${country.label}` as React.Key}
                  disablePadding
                  sx={styles.listItemContainer}
                  onClick={(e) => {
                    onCountryLanguageClick(e, country.locale)

                    router.push(country.href, country.href, {
                      locale: country.locale,
                    })
                  }}
                >
                  <CountryLanguageLink
                    link={{ label: country.label, href: country.href }}
                    locale={country.locale}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default CountryLanguageRegion
